const esJSON = {
  'Fill in the fields below to sign into your account.': 'Complete los campos a continuación para iniciar sesión en su cuenta',
  "Rows per page:": "Filas por página:",
  "This email is already registered. Please try a different one": "Este correo electrónico ya se encuentra registrado. Por favor intente con un correo electrónico diferente",
  "Attachment": "Adjunto",
  "The user was updated successfully": "El usuario fue actualizado correctamente",
  "The user has not been updated. Please try again": "El usuario no ha podido ser actualizado. Por favor intente de nuevo",
  "We couldn't find any users matching your search criteria": "No se encontraron usuarios de acuerdo al criterio de busqueda",
  'Download last report': 'Descarga último reporte',
  'The first name field is required': 'El nombre es requerido',
  'The last name field is required': 'El apellido es requerido',
  'The password field is required': 'La contraseña es requerida',
  'The rut field is required': 'El RUT es requerido',
  'The RUT field is required': 'El RUT es requerido',
  'Both password fields need to be the same': 'Ambas contraseñas deben ser iguales',
  'The email field is required': 'El correo es requerido',
  'The email provided should be a valid email address': 'El correo proviste, debe ser un correo válido',
  'This field is required': 'Este campo es requerido',
  'password must be at least 6 characters': 'La clave debe ser de al menos 6 caracteres',
  'The fullname field is required': 'El nombre completo es requerido',
  'The email physic field is required': 'El correo fisico es requerido',
  'The sector field is required': 'El sector es requerido',
  'The comune field is required': 'La comuna es requerida',
  'The kilometer field is required': 'El kilomentro es requerido',
  'Full Name': 'Nombre Completo',
  'The description field is required': 'La descripción es requerida',
  'Search by number...': 'Buscar por número de evento',
  'Search by user': 'Buscar por usuario',
  'The phone field is required': 'El teléfono es requerido',
  'Sign in': 'Iniciar Sesión',
  'I accept the': 'Acepto los',
  'Enter the email used for registration to reset your password.': 'Ingrese su correo, ingresado en el registro para reestablecer su contraseña',
  'terms and conditions': 'terminos y condiciones',
  'Want to try to sign in again?': 'Quieres intentar ingresar de nuevo?',
  'Click here': 'Haga clic aquí',
  'Send me a new password': 'Enviame una nueva contraseña',
  'Email address': 'Correo electronico',
  'Lost password?': 'Olvidó su contraseña?',
  'Don’t have an account, yet?': 'Aún no tienes una cuenta?',
  'Authentication Successful': 'Autenticación correcta',
  'Sign up here': 'Crea una cuenta aqui',
  'The comment has been saved sucessfully': "El comentario ha sido guardado con exito",
  'The event was created successfully': 'El evento fue creado con exito',
  'The event was updated successfully': 'El evento fue actualizado con exito',
  'An error occurred while attaching the file. Please contact the administrator': 'Ha ocurrido un error al adjuntar el archivo. Por favor contacte con el administrador',
  'The file was uploaded successfully': 'El archivo fue cargado con exito',
  'The event was assigned successfully': 'El evento fue asignado cor exito',
  'The ticket was closed successfully': 'El ticket fue cerrado correctamente',
  'The ticket was not closed': 'El ticket no fue cerrado',
  'Created': 'Creado',
  'Add user': 'Agregar usuario',
  'The user was created successfully': 'El usuario fue creado con exito',
  'New User': 'Nuevo Usuario',
  'First Name': 'Nombre',
  'Last Name': 'Apellido',
  'Date Created': 'Fecha de creación',
  'Users Management': 'Gestor de usuarios',
  'Create user': 'Crear usuario',
  'Address': 'Dirección',
  'Type user': 'Tipo de usuario',
  'Permissions': 'Permisos',
  'Description': 'Descripción',
  'Update user': 'Actualizar usuario',
  'Delete or Suspend': 'Eliminar o Suspender',
  'Suspend user': 'Suspender usuario',
  'Delete user': 'Eliminar usuario',
  'Here you can add or delete users': 'Aqui podras agregar o eliminar usuarios',
  'Purpose event select': 'Seleccione el proposito del evento',
  'Please select one type': 'Seleccione un tipo de evento',
  'Date Event': 'Fecha del evento',
  'Commune': 'Comuna',
  'View Detail': 'Ver detalle',
  'Orientation': 'Orientación',
  'Update event': 'Actualizar evento',
  'COMMENT_ADDED': 'Comentario',
  'No notifications found': 'No se han encontrado notificaciones',
  'First name': "Nombre",
  'Continue to sign in': 'Continuar para iniciar sesión',
  'Last name': "Apellido",
  'Password': "Clave Secreta",
  'Confirm password': "Confirmar clave secreta",
  'Phone number': 'Teléfono',
  'Personal Informations': "Información personal",
  "Complete Registration": "Completar registro",
  "Previous": "Anterior",
  "Complete registration": "Completar registro",
  "Create account": "Crear cuenta",
  "Fill in the fields below to sign up for an account.": "Complete los campos a continuación para registrarse.",
  "Submitting": "Enviando",
  "A confirmation has been sent to your email address": "Se ha enviado una confirmación a tu correo",
  "Check your email to confirm your email and start using your account": "Revise su correo para confirmar su correo electrónico y comenzar a usar su cuenta",
  "Continue to sig in": "Continuar para iniciar sesión",
  'SENT': 'Enviado',
  'North': 'Norte',
  'South': 'Sur',
  'Mark event as closed': 'Marcar evento como cerrado',
  'Close this event': 'Cerrar este evento',
  'SENDED': 'ENVIADO',
  "We couldn't find any assigment matching your search criteria": 'No se encontro ningun evento para asignar segun los criterios de busqueda',
  "We couldn't find any events matching your search criteria": 'No se encontro ningun evento segun los criterios de busqueda',
  "We couldn't find any event matching your search criteria": 'No se encontro ningun evento segun los criterios de busqueda',
  'Classification': 'Clasificación',
  'Edit': 'Editar',
  'Assigned': 'Asignado',
  'Edit event': 'Editar evento',
  'Rows per page': 'Filas por página',
  'Assign Tickets': 'Asignar Tickets',
  'Download report': 'Descargar reporte',
  'New Events': 'Nuevos eventos',
  'Late Events': 'Eventos atrasados',
  'Closed Events': 'Eventos cerrados',
  'Here you can assign events': 'Aqui puedes asignar tickets',
  'Download the monthly report': 'Descarga el reporte mensual',
  'Events Recent': 'Eventos recientes',
  'There will see all events': 'Aquí verás todos los eventos',
  'Personal Information': 'Datos Personales',
  'Name': 'Nombre',
  'Email': 'Correo',
  'Phone': 'Teléfono',
  'Email Physical': 'Correo Físico',
  'February': 'Febrero',
  'March': 'Marzo',
  'April': 'Abril',
  'May': 'Mayo',
  'June': 'Junio',
  'July': 'Julio',
  'August': 'Agosto',
  'Weeks': 'Semanas',
  'See all reports': 'Ver todos los reportes',
  'reports per month': 'Reportes por mes',
  'Requests': 'Solicitudes',
  'Report complete february': 'Reporte completo de febrero',
  'Report complete march': 'Reporte completo de marzo',
  'Report complete april': 'Reporte completo de abril',
  'Report complete may': 'Reporte completo de mayo',
  'Report complete june': 'Reporte completo de junio',
  'Report complete july': 'Reporte completo de julio',
  'Report complete august': 'Reporte completo de agosto',
  'Month': 'Mes',
  'Year': 'Año',
  'Please select one': 'Por favor seleccione un elemento',
  'Reports recent': 'Reportes recientes',
  'Report recent': 'Reporte reciente',
  'Assignments': 'Asignaciones',
  'Assignment': 'Asignar',
  'Ticket Number': 'Número de ticket',
  'Kilometer': 'Kilómetro',
  'North to South': 'Norte a Sur',
  'South to North': 'Sur a Norte',
  'Type event': 'Tipo de evento',
  'Reason for the event': 'Proposito del evento',
  'Assign Events': 'Asignar Eventos',
  'Assign me this event': 'Asigname este evento',
  'Assign to someone else': 'Asignar a otra persona',
  'answered your question': 'Respondio tu pregunta',
  'Here you can see all the notifications': 'Aqui veras todas las notificaciones',
  'Comment': 'Comentario',
  'Not assigned': 'No asignado',
  'assigned': 'Asignado',
  'Home': 'Inicio',
  'Events': 'Eventos',
  'Events Management': 'Administrador de eventos',
  'Create Event': 'Crear Evento',
  'Create event': 'Crear evento',
  'Congratulations': 'Felicitaciones',
  'New Event': 'Nuevo evento',
  'Number': 'Número de evento',
  'Date': 'Fecha',
  'Event': 'Evento',
  'Operator': 'Operador',
  'State': 'Estado',
  'Actions': 'Acciones',
  'Purpose Event': 'Propósito del evento',
  'Type Event': 'Tipo de evento',
  'Detail Event': 'Detalle del evento',
  'Add location': 'Agregar localizacion',
  'Pick a date': 'Seleccione una fecha',
  'Description of event': 'Descripción del evento',
  'Add a photo or file': 'Agregar foto o archivo',
  'Add Event': 'Agregar Evento',
  'Contact': 'Contacto',
  'Leave any events': 'Dejanos el evento que quieras',
  'You can contact us by this means': 'Nos puedes contactar por estos medios',
  'Claim': 'Reclamo',
  'Suggestion': 'Sugerencia',
  'Aswered': 'Respondido',
  'Managed': 'Gestionado',
  'Claims': 'Reclamos',
  'Suggestions': 'Sugerencias',
  'Comments': 'Comentarios',
  'Claims Management': 'Reclamos',
  'Suggestions Management': 'Sugerencias',
  'All': 'Todos',
  'Actives': 'Activos',
  'Closed': 'Cerrados',
  'Close': 'Cerrar',
  'Create claim': 'Crear reclamo',
  'Create suggestion': 'Crear sugerencia',
  'Add new claim': 'Agregar nuevo reclamo',
  'Add new suggestion': 'Agregar nueva sugerencia',
  'Complete all fields to create a new claim': 'Completa todos los campos para crear un nuevo reclamo',
  'Complete all fields to create a new suggestion': 'Completa todos los campos para crear una nueva sugerencia',
  'Title Claim': 'Titulo del reclamo',
  'Title Suggestion': 'Titulo de la sugerencia',
  'Cancel': 'Cancelar',
  'Answered': 'Contestadas',
  'We couldn\'t find any suggestions matching your search criteria': 'No se encontraron sugerencias que concuerden con su criterio de busqueda',
  'We couldn\'t find any claims matching your search criteria': 'No se encontraron reclamos que concuerden con su criterio de busqueda',
  Blueprints: 'Planos',
  'Extended Sidebar': 'Barra lateral extendida',
  'Accent Sidebar': 'Barra lateral de acento',
  'Accent Header': 'Encabezado de acento',
  'Boxed Sidebar': 'Barra lateral en caja',
  'Collapsed Sidebar': 'Barra lateral contraída',
  'Bottom Navigation': 'Navegación inferior',
  'Top Navigation': 'Navegación superior',
  'Lead Developer': 'Desarrollador principal',
  'Mega menu': 'Mega menú',
  Doctors: 'Doctores',
  Reports: 'Informes',
  'Custom dashboard built using the included components':
    'Panel de control personalizado construido con los componentes incluidos',
  Hospital: 'Hospital',
  Export: 'Exportar',
  'Last year': 'El año pasado',
  Expenses: 'Gastos',
  Products: 'Productos',
  Statistics: 'Estadísticas',
  General: 'General',
  Automation: 'Automatización',
  'Data Display': 'Visualización de datos',
  Calendar: 'Calendario',
  Mailbox: 'Buzón',
  Dashboards: 'Cuadros de mando',
  Analytics: 'Analítica',
  Banking: 'Bancario',
  Commerce: 'Comercio',
  Crypto: 'Cripto',
  Finance: 'Finanzas',
  Fitness: 'Aptitud física',
  Healthcare: 'Cuidado de la salud',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descripción del hospital',
  Helpdesk: 'Mesa de ayuda',
  Learning: 'Aprendiendo',
  Monitoring: 'Supervisión',
  Tasks: 'Tareas',
  Applications: 'Aplicaciones',
  'File Manager': 'Administrador de archivos',
  'Jobs Platform': 'Plataforma de trabajos',
  Messenger: 'Mensajero',
  'Projects Board': 'Junta de proyectos',
  Management: 'Administración',
  Users: 'Usuarios',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  Projects: 'Proyectos',
  Shop: 'Tienda',
  'Products List': 'Lista de productos',
  'View Product': 'Ver el producto',
  'Create Product': 'Crear producto',
  Invoices: 'Facturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticación',
  Login: 'Acceso',
  Basic: 'Básico',
  Cover: 'Cubrir',
  Register: 'Registrarse',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar contraseña',
  Status: 'Estado',
  Foundation: 'Fundación',
  'Extra Pages': 'Páginas extra',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Próximamente',
  Maintenance: 'Mantenimiento',
  Overview: 'Visión general',
  'Layout Starters': 'Arrancadores de diseño',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentación',
  Welcome: 'Bienvenida',
  Help: 'Ayuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Soporte de contacto',
  Version: 'Versión',
  Search: 'Buscar',
  Notifications: 'Notificaciones',
  Settings: 'Ajustes',
  'Language Switcher': 'Selector de idioma',
  'Sign out': 'Desconectar',
  'Change Theme': 'Cambiar de tema',
  'View all notifications': 'Ver todas las notificaciones',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Panel de administración de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Vista previa en vivo',
  'Key Features': 'Características clave',
  dashboards: 'cuadros de mando',
  applications: 'aplicaciones',
  'management sections': 'secciones de gestión',
  'dark/light themes': 'temas oscuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración',
  'User Type': 'Tipo de Usuario',
  'To contact Ruta Sur, complete the following form and we will respond to you as soon as possible': 'Para contactar con RutaSur, complete los siguientes campos',
  'Jan': 'Enero',
  'Feb': 'Febrero',
  'Mar': 'Marzo',
  'Apr': 'Abril',
  'Jun': 'Junio',
  'Jul': 'Julio',
  'Ago': 'Agosto',
  'Sep': 'Septiembre',
  'Oct': 'Octubre',
  'Nov': 'Noviembre',
  'Dec': 'Diciembre',
  'Date Closed': 'Fecha de cierre'
};

export default esJSON;

import axios from 'axios';

const IS_TESTING = true;

const axiosInt = axios.create({
  baseURL: IS_TESTING ? "https://ssr-api-uat.survias.cl" : "https://ssr-api.survias.cl",
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers": "authorization",
    "Access-Control-Allow-Headers": "*"
  }
});


axiosInt.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }

    if (error.response.status === 404) {
      console.error('errorResponse', {
        'message': error.response.data.errors.message,
        'url': error.response.request.responseURL
      })
      alert("Ha ocurriido un error consultando el servicio: " + error.response.config.url + ', Por favor contacte con el administrador');
    }

    return Promise.reject(error);
  }

);

export default axiosInt;